@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


body {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

#bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#bg svg{
    position: absolute;
    left: 30%;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 2000px;
    max-height: 100vh;
    height: auto;
}

@media screen and (max-width: 740px) {
    #bg svg{
        left: 25%;
        min-width: 600px;
    }
}

@media screen and (min-width: 740px) {
    #bg svg{
        left: 290px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        min-width: 1000px;
    }
}

@media screen and (min-width: 1025px) {
    #bg svg{
        left: 460px;
        margin: -20px 0 0;
        min-width: 1200px;
    }
}

@media screen and (min-width: 1200px) {
    #bg svg{
        left: 460px;
        margin: 0;
        min-width: 1600px;
    }
}

@media screen and (min-width: 1600px) {
    #bg svg{
        left: 500px;
    }
}
